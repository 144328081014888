import {AfterContentInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Consultation} from '../../models/consultation';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {GlobalsService} from '../../globals.service';
import {ConsultationProvider} from '../../providers/consultation.service';
import {EmployeeService} from '../../providers/employee.service';
import {AuthService} from '../../auth/auth.service';
import * as moment from 'moment'

@Component({
  selector: 'consultation-form',
  templateUrl: 'consultation-form.html',
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in')
      ]),
      transition('* => void', [
        animate('200ms ease-out', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})

export class ConsultationFormComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() consultation: Consultation;
  @Input() addingLateConsultation = false;
  @Input() clientId: number;
  @Input() isEditing: boolean;
  @Input() displayAllEmployees: boolean;
  @Output() submission: EventEmitter<Consultation> = new EventEmitter<Consultation>();
  excuse: string;
  requireComment = false;
  isSubmitting = false;
  isLoaded = false;
  dateValid: boolean;
  moment = moment

  constructor( private consultService: ConsultationProvider,
               public globalVarService: GlobalsService,
               private auth: AuthService,
               public employeeService: EmployeeService ) {
  }

  ngOnInit() {
    console.log(this.auth.getCurrentUser());

    this.consultation.scheduled_post_consultation = this.consultation.scheduled_post_consultation ? true : null
    if (!this.consultation.comment_attributes) {
      this.consultation.comment_attributes = {
        body: '',
        employee_id: this.auth.getCurrentUser().employee?.id,
      }
    }
    console.log(this.consultation)
    console.log(this)
  }

  ngAfterContentInit() {
    this.isLoaded = true
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.consultation) this.consultation = changes.consultation.currentValue
    if (this.consultation?.date) {
      this.dateValid = true;
      this.consultation.date = moment(this.consultation.date).format('YYYY-MM-DD')
    }
    this.consultation.scheduled_post_consultation = this.consultation.scheduled_post_consultation ? true : null
  }

  checkForOther(selection) {
    console.log(selection);
    selection === 'not medically eligible' ? this.requireComment = true : this.requireComment = false
  }

  createConsult() {
    this.isSubmitting = true;
    this.consultation.submitted = true;
    if ( !this.consultation.showed_up ) {
      this.consultation.signed_up = false;
      this.consultation.excuse = 'no show'
    }
    if ( this.consultation.excuse == null ) { this.consultation.excuse = this.excuse }
    if ( this.addingLateConsultation ) {
      // TODO: Remove this
      this.consultation.location_id = this.auth.getUser().employee.location.id
    }
    if (this.consultation.signed_up && this.clientId) {
      this.consultation.client_id = this.clientId
    }

    this.consultService.update(this.consultation).subscribe(
      success => {
        this.submission.emit(success);
        this.isSubmitting = false;
      },
      err => {
        this.globalVarService.handleResponse(err.error, true);
        this.submission.emit();
        this.isSubmitting = false;
      }
    )
  }

  test($event: { value: string; valid: boolean }) {
    console.log($event, this.consultation)
  }
}
