import {IClientDoc} from './client_doc';
import {IMergedDocument} from './merged_documents';
import {IExternalDocument} from './external_document';

export interface ISignedDocument {
  external_document_id?: number
  client_id?: number
  folder_id?: number
  id?: number
  sent_at?: string
  signed_at?: string
  viewed_at?: string
  external_document?: IExternalDocument;
  signer_details?: {email?: string; first_name?: string, last_name?: string}
  name?: string;
}

export class SignedDocument implements ISignedDocument {
  external_document_id?: number
  client_id?: number
  folder_id?: number
  id?: number
  sent_at?: string
  public signed_at?: string
  viewed_at?: string
  name?: string;
  external_document?: IExternalDocument;
  signer_details?: {email?: string; first_name?: string, last_name?: string}

  constructor(signedDocument: ISignedDocument) {
    Object.entries(signedDocument).forEach(entry => {
      this[entry[0]] = entry[1]
    })
  }

  signerInfo(): string {
    if (this.signer_details) {
      return `Name: ${this.signer_details.first_name} ${this.signer_details.last_name} - Email: ${this.signer_details.email}`
    }
  }

  getName(): string {
    if (this.external_document) {
      return this.external_document.name
    } else {
      return 'Missing document name!'
    }
  }

  convertToMergedDoc(): IMergedDocument {
    let badgeName = ''
    let color = 'warning'

    if (this.signed_at) {
      badgeName = 'Signed'
      color = 'success'
    } else if (this.viewed_at) {
      badgeName = 'Viewed'
    } else if (this.sent_at) {
      badgeName = 'Sent'
    }

    const mergedDoc = {
      name: this.name,
      client_id: this.client_id,
      url: `https://www.esigngenie.com/esign/api/folders/download?folderId=${this.folder_id}`,
      date: (this.signed_at || this.viewed_at || this.sent_at),
      badge: {
        name: badgeName,
        color
      },
      className: 'SignedDocument',
      canBeViewed: this.signed_at != null,
      canBeDeleted: false,
      external_document_id: this.external_document_id,
      id: this.id
    }
    return mergedDoc
  }


  /**
   * The highest status for the document. sent < viewed < signed
   */
  highestStatus(): {field: string, date: string} {
    if (this.signed_at) {
      return {field: 'signed_at', date: this.signed_at}
    } else if (this.viewed_at) {
      return {field: 'viewed_at', date: this.viewed_at}
    } else if (this.sent_at) {
      return {field: 'sent_at', date: this.sent_at}
    }
  }
}
