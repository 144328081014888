import { IComment } from "./comment";
import { ISignedDocument } from "./signed_document";

// tslint:disable:variable-name
export interface IConsultation {
  id?: number;
  name?: string;
  submitted?: boolean;
  showed_up?: boolean;
  email?: string;
  signed_up?: boolean;
  client_id?: number;
  consultant_id?: number;
  phone?: string;
  post_consultant_id?: number;
  comeback_type?: string|number;
  consultant?: string;
  post_consultant?: string;
  excuse?: string;
  referred_by?: string;
  consultation_type?: string|number;
  unique_mb?: number;
  location_id?: number;
  comeback?: boolean;
  created_at?: string;
  updated_at?: string;
  mindbody_id?: number;
  scheduled_post_consultation?: boolean;
  post_consultation_date?: string;
  attended_post_consultation?: boolean;
  comment_attributes?: IComment;
  signed_documents?: ISignedDocument[];
}

export class Consultation {
  public id?: number;
  public name?: string;
  public submitted?: boolean;
  public showed_up?: boolean;
  public email?: string;
  public phone?: string;
  public signed_up?: boolean;
  public client_id?: number;
  public consultant_id?: number;
  public post_consultant_id?: number;
  public excuse?: string;
  public unique_mb?: number;
  public comeback?: boolean;
  public referred_by?: string;
  public location_id?: number;
  public consultation_type?: string|number;
  public comeback_type?: string|number;
  public created_at?: string;
  public date?: string;
  public updated_at?: string;
  public mindbody_id?: number;
  public consultant?: string;
  public scheduled_post_consultation?: boolean;
  public post_consultation_date?: string;
  public attended_post_consultation?: boolean;
  public comment_attributes?: IComment;
  public signed_documents?: ISignedDocument[];

  constructor(data: Partial<IConsultation>) {
    Object.assign(this, data);
  }
}

export interface IConsultationAppointment {
  name?: string;
  email?: string;
  phone?: string;
  mindbody_id?: number,
  appointment_type?: string;
  consultant?: string;
  post_consultant?: string;
  submitted?: boolean;
  consultation?: Consultation
}

export class ConsultationAppointment {
  public name?: string;
  public email?: string;
  public mindbody_id?: number;
  public phone?: string;
  public appointment_type?: string;
  public consultant?: string;
  public post_consultant?: string;
  public submitted?: boolean;
  public consultation?: Consultation;

  constructor(data: Partial<IConsultationAppointment>) {
    Object.assign(this, data);
  }
}
// tslint:enable:variable-name
