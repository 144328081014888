<ion-item-sliding class="shaded-slider">

  <ion-item [detail]="true" class="icon-list" [ngClass]="{'loadThisClient': !requires_invite}" (click)="handleClientClick()">
    <ion-avatar>
      <i *ngIf="isConsultation || status != 'Completed'"
         class="fa left fa-user-circle pr-2" style="font-size:30px;color:red;"
      ></i>

      <ion-icon *ngIf="!isConsultation && status == 'Completed'"
                style="font-size:37px;color:green;margin-left:-3px;" name="checkmark-circle"
      ></ion-icon>
    </ion-avatar>

    <client-row-content
      [type]="type"
      [status]="status"
      [client]="client"
      [locationName]="locationService.getLocationName(client.location_id)"
      [showDetail]="isSearching"
    ></client-row-content>
    <ion-badge *ngIf="client.signed_into_app === false" color="danger" slot="end">Missing App!</ion-badge>

    <ion-icon *ngIf="requires_invite" slot="end" class="ion-text-right" color="primary" name="add-circle-outline"></ion-icon>

    <ion-text *ngIf="!isSearching" class="subtle" slot="end">
      <small>{{ client.time | amUtc | amDateFormat: 'h:mm a' }}</small>
    </ion-text>
  </ion-item>

  <ion-item-options side="end" class="sliding-button">
    <ion-item-option class="deleteClient" color="danger" (click)="selectClientForDeletion(client)">
      <div class="flex align-items-center flex-column">
        <ion-icon name="trash"></ion-icon>
        <ion-text>Delete</ion-text>
      </div>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>
